.accueil-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  // Styles communs pour les sections .accueil-top-content, .accueil-middle-content, et .accueil-pourquoi-devmemories
  .accueil-top-content,
  .accueil-middle-content,
  .accueil-pourquoi-devmemories {
    animation-delay: 1s;
    color: #222222;
    border-radius: 5px;
    margin: 50px 100px;
    padding: 20px 60px;
    animation: fadeIn 0.5s ease-out forwards;
    border-radius: 5px;
    background: linear-gradient(185deg, #f1f1f1);
    box-shadow: 5px 5px 10px #b9b9b9, -5px -5px 10px #ffffff;
    &.closing {
      animation: fadeOut 0.5s ease-out forwards;
    }
  }

  .accueil-top-content {
    color: #fff;
    text-align: left;
    padding: 0;
  }

  .accueil-middle-content,
  .accueil-pourquoi-devmemories {
    background-color: #f9f9f9;
  }

  // Styles spécifiques pour .accueil-top-content
  .accueil-top-content {
    align-items: left;
    padding-left: 50px;
    padding-bottom: 20px;
    color: #222222;
    h1 {
      font-size: 3em;
    }

    p {
      text-align: left;
      padding-right: 390px;
      line-height: 2em;
    }
  }

  // Styles spécifiques pour .accueil-middle-content
  .accueil-middle-content {
    gap: 30px;
    margin: 0px 100px;

    .accueil-explications-app {
      h2 {
        background-color: #22506c;
        max-width: max-content;
        padding: 5px 20px;
        color: #ffffff;
        border-radius: 5px;
        border-radius: 5px;
        background: linear-gradient(185deg, #f1f1f1);
        box-shadow: 5px 5px 10px #b9b9b9, -5px -5px 10px #ffffff;
      }
    }

    .accueil-explications-fonctionnalitees ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  // Styles spécifiques pour .accueil-pourquoi-devmemories
  .accueil-pourquoi-devmemories {
    h2 {
      background-color: #22506c;
      max-width: max-content;
      padding: 5px 20px;
      color: #ffffff;
      border-radius: 5px;
      border-radius: 5px;
      background: linear-gradient(185deg, #f1f1f1);
      box-shadow: 5px 5px 10px #b9b9b9, -5px -5px 10px #ffffff;
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
    }

    .resume {
      background-color: #22506c;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      font-weight: 600;
      border-radius: 5px;
      background: linear-gradient(185deg, #f1f1f1);
      box-shadow: 5px 5px 10px #b9b9b9, -5px -5px 10px #ffffff;
    }
  }
}

// Styles pour la scrollbar
.accueil-content::-webkit-scrollbar {
  width: 10px; // Largeur de la scrollbar
}

.accueil-content::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

.accueil-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;

  &:hover {
    background: #555;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
