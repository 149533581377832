// Loader animation
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  background: linear-gradient(0deg, #22506c 33%, #fddc30 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #ffffff;
    box-sizing: border-box;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
