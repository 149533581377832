// Style pour le composant : "SearchBar.jsx"
.search_bar-container {
  display: flex;
  align-items: center;
  .search_bar {
    height: 53px;
    width: 601px;
    padding-left: 70px;
    padding-right: 20px;
    border-radius: 15px;
    border: none;
  }
  .magnify_glass {
    position: relative;
    left: 45px;
    top: 5px;
    font-size: 1.7em;
  }
}

@media screen and (max-width: 1920px) {
  .search_bar-container {
    .search_bar {
      width: 501px;
      height: 43px;
    }
  }
}

@media screen and (max-width: 750px) {
  .search_bar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 30px;

    .search_bar {
      width: auto;
      height: 33px;
      font-size: 0.7em;
    }
    .magnify_glass {
      font-size: 1em;
      top: 2px;
      left: 25px;
    }
  }
}
