//Style pour le composant "PanelProfil.jsx"
.panel_profil_container {
  display: flex;
  align-items: center;
  margin-right: 40px;
  gap: 60px;
  .panel_profil {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    .image_profil {
      font-size: 3.7em;
      margin-top: 20px;
      color: #fff;
      .image-defaut {
        box-shadow: 2px 3px 15px 0.1px #000000;
        border-radius: 50px;
      }
      .image-user {
        height: 50px;
        border-radius: 50px;
      }
    }
    .profil {
      display: flex;
      flex-direction: column;
      align-items: center;
      .name_profil {
        font-size: 1.1em;
        color: #fff;
      }
      .edit_profil {
        border: none;
        width: 100px;
        height: 19px;
        font-size: 0.7em;
        background-color: #fff;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0px 10px 25px -4px #000000;
      }
    }
  }
  .panel-profil-no-auth {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex; /* Active Flexbox */
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    background-color: rgba(0, 0, 0, 0.425);
    .message-panel-profil {
      background-color: #22506c;
      color: #fff;
      padding: 30px;
      border-radius: 1em;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 28vw;
      box-shadow: 0px 0px 3px 1px#ffffff3f;
      .panel-profil-no-auth-btns {
        display: flex;
        gap: 3em;
        .message-panel-profil-link,
        .message-panel-profil-close {
          background-color: #ffffff;
          color: #000000;
          padding: 5px 10px;
          border-radius: 3em;
          border: none;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}
/*** Media query ***/

@media screen and (max-width: 1024px) {
  .panel_profil_container {
    position: relative;
    right: 10px;
    bottom: 12px;
    .panel_notification {
      margin-top: 30px;
      position: relative;
      left: 33px;
      font-size: 1.5em;
      color: #fff;
      cursor: pointer;
    }
    .panel_profil {
      flex-direction: column;
      gap: 0;
      .image_profil {
        font-size: 1.6em;
      }
      .profil {
        .name_profil {
          display: none;
        }
        .edit_profil {
          height: 17px;
          width: 55px;
        }
      }
    }
  }
}

// Style de la modal du panel-profil

.modal-overlay {
  position: fixed; /* Pour que l'overlay couvre toute la fenêtre */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex; /* Active Flexbox */
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  animation: edit-overlay 0.5s ease;
  transition: edit-overlay 0.5s ease;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optionnel : pour ajouter un effet de fond */
  .modal-content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 25px;
      margin: 0 30px 30px 30px;
      label {
        display: flex;
        gap: 10px;
      }
      .image-profil {
        input[type="file"]::file-selector-button {
          cursor: pointer;
          border-radius: 1em;
          font-size: 0.9em;
          background-color: #22506c;
          padding: 5px 10px;
          border: none;
          color: #fff;
        }
      }
      .buttons-edit {
        display: flex;
        gap: 10px;
        .btn-edit-one,
        .btn-edit-two {
          background-color: #22506c;
          border: none;
          color: #fff;
          padding: 5px 10px;
          border-radius: 1em;
          cursor: pointer;
        }
      }
    }
  }
}
.delete-user {
  .delete-account-btn {
    background-color: #ffaaaa;
    padding: 5px 10px;
    border-radius: 3em;
    border: none;
    color: #af0808;
    font-weight: 600;
  }
  .last-message-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    .last-message-delete {
      background-color: #22506c;
      color: #fff;
      padding: 30px;
      border-radius: 1em;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 28vw;
      border: 1px solid yellow;
      .question-message-delete {
        margin-bottom: 10px;
      }
      .text-delete-account-mdp {
        font-size: 0.6em;
        font-weight: 400;
        margin-bottom: 10px;
      }
      .input-password-delete {
        border-radius: 5px;
        padding: 5px;
      }
      .buttons-last-message {
        margin-top: 30px;
        display: flex;
        gap: 5vw;
        .buttons-last-message-one,
        .buttons-last-message-two {
          border: none;
          padding: 10px;
          width: 8em;
          border-radius: 3em;
          cursor: pointer;
          font-weight: 600;
        }
        .buttons-last-message-one {
          background-color: #ffaaaa;
          color: #af0808;
        }
        .buttons-last-message-two {
          background-color: #ffffff;
          color: #000000;
        }
      }
    }
  }
  .delete-message {
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .delete-message-confirmation {
      background-color: green;
      color: #fff;
      border-radius: 5px;
      padding: 10px;
    }

    .animate-in {
      animation: message-slide-down 0.5s forwards;
    }

    .animate-out {
      animation: message-slide-up 0.5s forwards;
    }
  }
}

@media screen and (max-width: 751px) {
  .panel_profil_container {
    display: flex;
    align-items: center;
    margin-right: 0px;
    gap: 10px;
    .panel_profil {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      .image_profil {
        font-size: 1.5em;
        margin-top: 20px;

        color: #fff;
        .image-defaut {
          box-shadow: 2px 3px 15px 0.1px #000000;
          border-radius: 50px;
        }
        .image-user {
          height: 50px;
          border-radius: 50px;
        }
      }
      .profil {
        display: flex;
        flex-direction: column;
        align-items: center;
        .name_profil {
          font-size: 0.5em;
          color: #fff;
        }
        .edit_profil {
          border: none;
          width: max-content;
          height: 19px;
          font-size: 0.7em;
          background-color: #ffffff;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }
}

@keyframes delete-overlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes message-slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes message-slide-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
