@import "../../../_variables.scss";

.container {
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  border-radius: $border-radius;
  background: linear-gradient(185deg, $background-color);
  box-shadow: $box-shadow;
  overflow-y: auto;

  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 90px;
    animation: top-content-wrait 1s ease;

    .title p {
      font-size: $font-size-small * 0.75;
      font-weight: 400;
    }

    .right-elements {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 30px;
    }
  }

  // Style pour la scroll bar
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $white;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;

    &:hover {
      background: #555;
    }
  }
}

@media screen and (max-width: 1124px) {
  .container {
    width: 100%;
    height: calc(100% - 100px);
    border-radius: $border-radius;
    background: linear-gradient(185deg, $background-color);
    box-shadow: $box-shadow;
    overflow-y: auto;

    .top-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 90px;
      gap: 50px;
      animation: top-content-wrait 1s ease;

      .title p {
        font-size: $font-size-small * 0.75;
        font-weight: 400;
      }

      .right-elements {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 30px;
      }
    }

    // Style pour la scroll bar
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: $white;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;

      &:hover {
        background: #555;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    width: 100%;
    height: calc(100% - 100px);
    border-radius: $border-radius;
    background: linear-gradient(185deg, $background-color);
    box-shadow: $box-shadow;
    overflow-y: auto;
    overflow-x: hidden;

    .top-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 0 90px;
      gap: 50px;
      animation: top-content-wrait 1s ease;

      .title p {
        font-size: $font-size-small * 0.75;
        font-weight: 400;
      }

      .right-elements {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 30px;
      }
    }

    // Style pour la scroll bar
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: $white;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;

      &:hover {
        background: #555;
      }
    }
  }
}

// Animation pour l'apparition des éléments du haut
@keyframes top-content-wrait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}