.cards_container {
  margin: 60px 130px 50px 130px;
  padding: 10px;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

  .notes-card {
    border-radius: 5px;
    border: 1px solid #aca4a4;
    background: #e0e0e0;
    box-shadow: 5px 5px 20px #decdcd, -15px -15px 30px #ffffff;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    transform: scale(0.1);
    opacity: 0;
    animation: expand-card 0.5s ease-in-out forwards;

    .notes-card-elements {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 20px;

      .top-contain-notes {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 15px;

        .title-and-category {
          flex-grow: 1;
          margin-right: 10px;

          h3 {
            font-size: 1.2em;
            margin: 0 0 10px 0;
            word-wrap: break-word;
            line-height: 1.2;
            max-height: 2.4em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .categorie {
            font-size: 0.8em;
            display: flex;
            align-items: center;
            gap: 5px;
            background-color: #bcd2fc;
            padding: 2px 8px;
            border-radius: 12px;
            width: fit-content;

            span,
            p {
              display: inline;
            }
          }
        }

        #btn-notes {
          flex-shrink: 0;
        }
      }

      .bottom-contain-notes {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 10px;

        .notes-images,
        .no-image {
          width: 100%;
          height: 200px;
          margin-bottom: 10px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
          }
        }

        .no-image {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #22506c;
          color: #fddc30;
          text-transform: uppercase;
          font-size: 1em;
        }

        .subtitle-span {
          background-color: #fff;
          box-shadow: inset 0 0 2px rgb(121, 50, 50);
          padding: 5px 10px;
          border-radius: 3px;
          color: #000;
          font-size: 0.8em;
          display: inline-block;
          margin: 2px;
        }
      }
    }
  }
}

@keyframes expand-card {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 1920px) {
  .cards_container {
    margin: 60px 50px 50px 50px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
