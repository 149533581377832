.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content-edit {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: max-content;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 80vh;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .modal-input-container-edit,
  .modal-textarea-container-edit {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #22506c;
    max-width: max-content;
    padding: 20px;
    color: #fff;
    border-radius: 5px;
    .modal-input-edit,
    .modal-text-area-edit {
      border: 1px solid black;
      border-radius: 3px;
      padding-left: 10px;
      font-size: 0.9em;
    }
    .modal-input-edit {
      height: 28px;
    }
    .modal-text-area-edit {
      width: 20vw;
      height: 10vw;
    }
  }
  .modal-edit-btns {
    display: flex;
    gap: 20px;
    .modal-modifier-btn,
    .modal-annuler-btn {
      background-color: #fec399;
      height: 30px;
      width: 100px;
      border: none;
      border-radius: 3em;
      color: #894a01;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.error-msg {
  color: red;
  margin-bottom: 10px;
}
