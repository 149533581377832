.login-page_container {
  position: absolute;
  height: calc(100% - 94px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 122px);
  top: 94px;
  left: 122px;

  h2 {
    font-size: 2.1em;
    color: #000000;
    margin-bottom: 100px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    .champs {
      display: flex;
      flex-direction: column;
      align-items: left;
      gap: 30px;
      .input-champ-login {
        display: flex;
        flex-direction: column;
        width: 20vw;
        height: 2vw;
      }
      .image-profil-loggin {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        height: max-content;
        background-color: #fff;
        .input-image-file-login[type="file"]::file-selector-button {
          background-color: #22506c;
          color: #fff;
          text-align: center;
          border: none;
          padding: 10px 15px;
          border-radius: 3em;
          cursor: pointer;
        }
      }
    }
    .login-btns {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-top: 50px;
      button {
        border: none;
        padding: 10px;
        background-color: #22506c;
        color: #fff;
        border-radius: 20px;
        cursor: pointer;
        width: 150px;
        cursor: pointer;
      }
    }
  }
}
