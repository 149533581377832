.cards_container_ressources {
  margin: 60px 130px 50px 130px;
  padding: 10px;
  display: grid;
  gap: 150px;
  grid-template-columns: repeat(auto-fill, minmax(470px, 1fr));

  .ressource-card {
    border-radius: 5px;
    border: 1px solid #aca4a4;
    background: #ffffff;
    box-shadow: 5px 5px 20px #decdcd, -15px -15px 30px #ffffff;
    height: max-content;
    width: 600px;
    overflow: hidden;
    opacity: 0;
    transform: scaleX(0);
    animation: expand-card 0.6s ease-out forwards;

    .ressource-card-elements {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      .top-contain-ressource {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 15px;

        h3 {
          font-size: 0.9em;
        }

        .category {
          z-index: 2;
          position: absolute;
          top: 50px;
          right: 0px;
          height: 40px;
          width: max-content;
          display: flex;
          align-items: center;
          background-color: #22506c;
          text-transform: uppercase;
          padding: 0 20px;
          border-radius: 0 0 0 10px;
          border-left: 2px solid #fddc30;
          border-bottom: 2px solid #fddc30;

          span {
            color: #fff;

            .category-name {
              padding: 0 0 0 10px;
            }
          }
        }
      }
      .ressources-other-content {
        cursor: pointer;
      }

      .video-player {
        background: linear-gradient(90deg, #e0e0e0, #f8f8f8, #e0e0e0);
        animation: gradient-load 2s ease-in-out infinite;
        animation-delay: 2s;
      }

      .no-image {
        height: 338px;
        position: relative;
        top: -25px;
        left: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3em;
        background-color: #22506c;
        color: #fddc30;
        text-transform: uppercase;
      }

      .bottom-contain-ressource {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 10px 15px;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;

        span {
          background-color: #fff;
          box-shadow: inset 0px 0px 2px rgb(121, 50, 50);
          padding: 5px 10px;
          border-radius: 3px;
          text-align: center;
          color: #000000;
          font-weight: 500;
          font-size: 0.7em;
        }
      }
    }
  }
}

@keyframes expand-card {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes gradient-load {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
