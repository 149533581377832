@import "../../../_variables.scss";

// Style pour le composant parent : NavBar
.nav-bar {
  background-color: $primary-color;
  width: 100%;
  height: $navbar-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Media Query

// NavBar
@media screen and (max-width: 1024px) {
  .nav-bar {
    width: 100vw;
  }

  @media screen and (max-width: 1920px) {
    .nav-bar {
      width: 100%;
      height: 84px;
      display: flex;
      justify-content: space-betwee;
      align-items: center;
    }
  }
}
@media screen and (max-width: 751px) {
  .nav-bar {
    background-color: #22506c;
    width: 100%;
    height: 94px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
  }
}
