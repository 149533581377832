.page_content {
  position: absolute;
  height: calc(100% - 94px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 122px);
  top: 94px;
  left: 122px;
  overflow: hidden;
}

.page_content_detail {
  position: absolute;
  height: calc(100% - 94px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 122px);
  top: 94px;
  left: 122px;
  overflow: hidden;
}

@media screen and (max-width: 750px) {
  .page_content {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: 294px;
    left: 0;
    overflow: hidden;
  }

  .page_content_detail {
    position: absolute;
    height: calc(100% - 94px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 122px);
    top: 94px;
    left: 122px;
    overflow: hidden;
  }
}
