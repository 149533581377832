.collapse {
  margin-bottom: 15px;
  width: 65em;
  input {
    display: none;
  }

  .collapse-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #22506c;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1.2em;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }

    .chevron-icon {
      transition: transform 0.3s ease;
      margin-left: 10px; // Espace entre le texte et l'icône
    }
  }

  .collapse-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    padding: 0 15px;
    background-color: #f1f1f1;
    border-radius: 0 0 5px 5px;
    color: #333;
    overflow-y: scroll;

    p {
      margin: 15px 0;
    }
  }

  input:checked + .collapse-title + .collapse-content {
    max-height: 600px; /* Ajustez la hauteur maximale en fonction du contenu */
    padding: 10px 15px;
  }

  input:checked + .collapse-title .chevron-icon {
    transform: rotate(180deg);
  }
}
