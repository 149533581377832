// Style pour le composant parent : SideBar
.side-bar {
  background-color: #22506c;
  width: max-content;
  display: flex;
  flex-direction: column;

  height: calc(100vh - 94px);
  .all-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    a {
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  .side-bar {
    height: 100vh;
  }
}

@media screen and (max-width: 1920px) {
  .side-bar {
    width: max-content;
    height: calc(100vh - 84px);
    .all-tabs {
      font-size: 0.8em;
    }
  }
}

@media screen and (max-width: 750px) {
  .side-bar {
    display: flex;

    width: 100%;
    height: auto;

    .all-tabs {
      margin-top: 20px;
      height: 100%;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }
}
