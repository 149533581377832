.ressource-content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  .ressource-top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    color: #ffffff;
    .ressource-content_top-text {
      display: flex;
      font-size: 1.7em;
      h3 {
        font-weight: 400;
        position: relative;
        bottom: 10px;
      }
    }

    .ressources_video {
      background-color: #fff;
      width: 80vw;

      .react-player {
        height: 60vw;
      }
    }
  }
  .ressource-content_text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    line-height: 2em;

    .card-content_ressource-block {
      display: flex;
      margin: 0 50px;
      height: auto;

      width: auto;

      align-items: center;
      flex-direction: column;
      border-radius: 5px;
    }

    .subtitle {
      color: #fff;
      background-color: #0000005f;
      border-top: 2px solid #fddb3018;
      border-left: 2px solid #fddb3018;
      border-right: 2px solid #fddb3018;

      padding: 10px 20px;
      position: relative;
      top: 20px;
      text-align: center;
    }

    .content-card {
      margin: 0;
      background-color: #0a171f;
      padding: 20px;
      border-radius: 5px;
      animation: content-wrait 0.7s linear;
      overflow: hidden;
      border: 2px solid #fddb3018;
      max-height: 600px;
      overflow-y: scroll;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .normal-text {
        font-size: 0.9em;
        width: 100vh;
      }
      code {
        width: max-content;
      }

      // Styles pour la scrollbar
      &::-webkit-scrollbar {
        width: 10px; /* Largeur de la scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: #c1c1c1; /* Couleur de la piste */
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #fddc30; /* Couleur de la barre */
        border-radius: 10px;

        &:hover {
          background: rgba(
            34,
            80,
            108,
            0.504
          ); /* Couleur de la barre au survol */
        }
      }
    }
  }
}

// Style pour la scroll bar
.ressource::-webkit-scrollbar {
  width: 10px; /* Largeur de la scrollbar */
}

.ressource::-webkit-scrollbar-track {
  background: #c1c1c1; /* Couleur de la piste */
  border-radius: 10px;
}

.ressource::-webkit-scrollbar-thumb {
  background: rgba(34, 80, 108, 0.504); /* Couleur de la barre */
  border-radius: 10px;
}

.ressource::-webkit-scrollbar-thumb:hover {
  background: #fddc30; /* Couleur de la barre au survol */
}
