@import "../../../../_variables.scss";
.cards_container_collection {
  margin: 60px 130px 50px 130px;
  padding: 10px;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

  .card {
    background-color: $white;
    width: 400px;
    height: max-content;
    padding: 20px;
    box-shadow: inset 0 -5em 5em rgba($primary-color, 0.209), 0 0 0 1px rgba($primary-color, 0.24),
      0.3em 0.3em 1em rgba($primary-color, 0.271);
    display: flex;
    flex-direction: column;
    transform: scale(0.1);
    opacity: 0;
    animation: expand-card 0.5s ease-in-out forwards;

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      position: relative;
      bottom: 3px;

      .card-title {
        font-size: $font-size-small * 1.125;
        max-width: 70%;
        word-wrap: break-word;
        margin: 0;
        line-height: 1.2;
        max-height: 2.4em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .buttons-card {
        flex-shrink: 0;
      }
    }

    .card-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .categorie {
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 1em 1em 0 0;
        background: linear-gradient(90deg, rgba($secondary-color, 0.64), $secondary-color, darken($secondary-color, 5%));
        width: max-content;
        height: 2em;
        padding: 0 10px;
        margin-bottom: 0;
        font-size: $font-size-small * 1.125;
        font-weight: 500;
        text-transform: uppercase;
        span,
        p {
          margin: 0;
        }
      }

      .image-container {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .card-image {
          width: 100%;
          height: 250px;
          object-fit: cover;
          border-radius: $border-radius;
        }

        .no-image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 250px;
          background-color: #e4e4e4;
          color: $primary-color;
          font-style: italic;
        }
      }
    }

    .card-footer {
      margin-top: 15px;

      .technos {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;

        .techno-tag {
          padding: 2px 8px;
          border-radius: 12px;
          font-size: $font-size-small;
          font-weight: 600;
          background-color: $white;
          box-shadow: inset 0 -5em 0.1em rgba($white, 0.209),
            0 0 0 1px rgba($primary-color, 0.24), 0.1em 0.1em 0.2em rgba($primary-color, 0.271);
        }
      }
    }
  }
}

@keyframes expand-card {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .cards_container_collection {
    margin: 60px 50px 50px 50px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    .card {
      width: 300px;
      .card-header {
        align-items: center;
        position: relative;
        bottom: 7px;

        .card-title {
          font-size: $font-size-small;
        }

        .buttons-card {
          position: relative;
          left: 7px;
        }
      }

      .card-body {
        .categorie {
          padding: 2px 15px;
          font-size: $font-size-small * 0.875;
        }
      }
    }
  }
}