.modal {
  .error-message {
    background-color: rgb(255, 0, 0);
    color: white;
    font-size: 0.7em;
    padding: 3px 10px;
    border-radius: 3px;
    font-weight: 600;
  }
  .modal-content {
    width: auto;
    max-height: 780px;
    background-color: rgb(241, 241, 241);
    border-radius: 10px;
    box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.18);
    border: 1px solid rgba(0, 0, 0, 0.2);
    animation: modal-content-wrait 0.2s linear;
    padding: 20px 30px;
    user-select: none;
    overflow-y: auto;

    .modal-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      gap: 10px;
      position: fixed;
      z-index: 1000;
      background-color: rgb(53, 53, 53);
      box-shadow: inset 0 -5em 5em rgba(34, 80, 108, 0.209), 0 0 0 1px #22506c3e,
        0.3em 0.3em 1em rgba(34, 80, 108, 0.271);
      padding: 5px 10px;
      border-radius: 3em;

      .close,
      .deplace {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        cursor: pointer;
        z-index: 3;
      }

      .close {
        font-size: 1.4em;
        background-color: #d9d9d9;
        color: #000;
      }

      .deplace {
        background-color: #bcd2fc;
        font-size: 1em;
        cursor: grab;
      }
    }

    .modal-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .modal-top-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        margin-top: 50px;

        .modal-title-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          .error-message {
            margin-top: 5px;
          }
          span {
            margin-bottom: 5px;
          }

          .modal-title {
            height: 30px;
            width: 280px;
            border: 1px solid black;
            border-radius: 3px;
            padding-left: 10px;
          }
        }

        .modal-select-img {
          display: flex;
          flex-direction: column;
          color: #555;
          border: 1px solid #22506c;
          border-radius: 3px;
          align-items: center;
          position: relative;
          .error-message {
            position: absolute;
            top: 36px;
          }
          span {
            position: absolute;
            bottom: 33px;
            font-size: 0.9em;
            font-weight: 500;
          }

          .select-img-text {
            input[type="file"]::file-selector-button {
              cursor: pointer;
              font-size: 0.9em;
              background-color: #22506c;
              padding: 5px 10px;
              height: 30px;
              border: none;
              color: #fff;
              margin-right: 12px;
            }
          }
        }

        .modal-video-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            padding-bottom: 5px;
            font-size: 0.9em;
          }
        }
      }

      .modal-category-content {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        border-radius: 3px;
        background-color: #fec399;
        position: relative;
        padding-top: 10px;

        .option-categorie-create {
          display: flex;
          justify-content: space-between;

          .span-categorie-name {
            color: blue;
          }
        }

        .modal-input-categorie {
          width: max-content;
          margin: 10px;
          height: 24px;
          padding-left: 10px;
          border-radius: 5px;
          border: none;
        }
        .button-add-categorie {
          background-color: #bcd2fc;
          border: none;
          border-radius: 50%;
          height: 25px;
          width: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }

      .modal-textarea-container {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        gap: 15px;
        align-items: center;
        background-color: rgba(34, 80, 108, 0.1);
        padding: 10px;
        font-size: 0.75em;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .error-message {
          font-size: 0.9em;
        }

        .remove-textarea {
          color: #fff;
          background-color: rgba(255, 0, 0, 0.4);
          border: none;
          border-radius: 50%;
          padding: 5px 5px 2px;
          cursor: pointer;
        }

        .modal-input-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px;

          .modal-input {
            border: none;
            background-color: #22506c;
            color: #fff;
            height: 30px;
            border-radius: 3px;
            padding-left: 10px;
            width: 220px;

            &::placeholder {
              color: #fff;
            }
          }
        }

        .modal-text-area {
          background-color: #22506c;
          border-radius: 5px;
          min-width: 400px;
          min-height: 10px;
          padding: 10px;
          color: #fff;
          display: flex;
          align-items: center;

          &::placeholder {
            color: #fff;
          }
        }

        .text-area-area {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          position: relative;
        }
      }

      .add-textarea {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 25px;
        background-color: #bcd2fc;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        margin-top: 10px;
      }

      .modal-btn-submit {
        background-color: #fec399;
        height: 30px;
        border-radius: 10px;
        border: none;
        padding: 0 10px;
        margin-top: 50px;
        font-size: 0.77em;
        cursor: pointer;
        color: #894a01;
        font-weight: 600;
      }
    }
  }
}

// Styles pour les barres de défilement
.modal-content::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  width: 10px;
}

.modal-content::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

.modal-content::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.modal-content::-webkit-scrollbar-thumb:hover,
textarea::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// Animation pour le contenu modal
@keyframes modal-content-wrait {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1920px) {
  .modal {
    .error-message {
      background-color: rgb(255, 0, 0);
      color: white;
      font-size: 0.6em;
      padding: 3px 10px;
      border-radius: 3px;
      font-weight: 600;
    }
    .modal-content {
      width: auto;
      max-height: 650px;
      background-color: rgb(241, 241, 241);
      border-radius: 10px;
      box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.18);
      border: 1px solid rgba(0, 0, 0, 0.2);
      animation: modal-content-wrait 0.2s linear;
      padding: 20px 30px;
      user-select: none;
      overflow-y: auto;

      .modal-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 10px;
        position: fixed;
        z-index: 1000;
        background-color: rgb(53, 53, 53);
        box-shadow: inset 0 -5em 5em rgba(34, 80, 108, 0.209),
          0 0 0 1px #22506c3e, 0.3em 0.3em 1em rgba(34, 80, 108, 0.271);
        padding: 5px 10px;
        border-radius: 3em;

        .close,
        .deplace {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 23px;
          height: 23px;
          border-radius: 50%;
          cursor: pointer;
          z-index: 3;
        }

        .close {
          font-size: 1.4em;
          background-color: #d9d9d9;
          color: #000;
        }

        .deplace {
          background-color: #bcd2fc;
          font-size: 1em;
          cursor: grab;
        }
      }

      .modal-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .modal-top-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 40px;
          margin-top: 30px;
          font-size: 0.8em;

          .modal-title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            .error-message {
              margin-top: 5px;
              font-size: 0.87em;
            }
            span {
              margin-bottom: 5px;
            }

            .modal-title {
              height: 25px;
              width: 270px;
              border: 1px solid black;
              border-radius: 3px;
              padding-left: 10px;
              font-size: 0.9em;
            }
          }

          .modal-select-img {
            display: flex;
            flex-direction: column;
            color: #555;
            border: 1px solid #22506c;
            border-radius: 3px;
            align-items: center;
            position: relative;
            .error-message {
              position: absolute;
              top: 36px;
            }
            span {
              position: absolute;
              bottom: 33px;

              font-weight: 500;
            }

            .select-img-text {
              input[type="file"]::file-selector-button {
                cursor: pointer;
                font-size: 0.8em;
                background-color: #22506c;
                padding: 0px 10px;
                height: 20px;
                border: none;
                color: #fff;
                margin-right: 12px;
              }
            }
          }

          .modal-video-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
              padding-bottom: 5px;
              font-size: 0.8em;
            }
          }
        }

        .modal-category-content {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: center;
          border-radius: 3px;
          background-color: #fec399;
          position: relative;
          padding-top: 10px;
          font-size: 0.8em;

          .option-categorie-create {
            display: flex;
            justify-content: space-between;

            .span-categorie-name {
              color: blue;
            }
          }

          .modal-input-categorie {
            width: max-content;
            margin: 10px;
            height: 19px;
            padding-left: 10px;
            border-radius: 5px;
            border: none;
            font-size: 0.8em;
          }
          .button-add-categorie {
            background-color: #bcd2fc;
            border: none;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            cursor: pointer;
          }
        }

        .modal-textarea-container {
          display: flex;
          flex-direction: column;
          margin-top: 30px;
          gap: 15px;
          align-items: center;
          background-color: rgba(34, 80, 108, 0.1);
          padding: 10px;
          border-radius: 2px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .error-message {
            font-size: 0.9em;
          }

          .remove-textarea {
            color: #fff;
            background-color: rgba(255, 0, 0, 0.4);
            border: none;
            border-radius: 50%;
            padding: 5px 5px 2px;
            cursor: pointer;
          }

          .modal-input-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;

            .modal-input {
              border: none;
              background-color: #22506c;
              color: #fff;
              height: 30px;
              border-radius: 3px;
              padding-left: 10px;
              width: 200px;
              font-size: 1em;

              &::placeholder {
                color: #fff;
              }
            }
          }

          .modal-text-area {
            background-color: #22506c;
            border-radius: 5px;
            min-width: 400px;
            min-height: 10px;
            padding: 10px;
            color: #fff;
            display: flex;
            align-items: center;
            font-size: 1.1em;

            &::placeholder {
              color: #fff;
            }
          }

          .text-area-area {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            position: relative;
          }
        }

        .add-textarea {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 25px;
          width: 25px;
          background-color: #bcd2fc;
          border: none;
          border-radius: 50%;
          cursor: pointer;
          margin-top: 10px;
        }

        .modal-btn-submit {
          background-color: #fec399;
          height: 30px;
          border-radius: 10px;
          border: none;
          padding: 0 10px;
          margin-top: 50px;
          font-size: 0.77em;
          cursor: pointer;
          color: #894a01;
          font-weight: 600;
        }
      }
    }
  }
}
