@import "../../../_variables.scss";

.btn-card__container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: inset 0 -5em 5em rgba(34, 80, 108, 0.209), 0 0 0 1px #22506c3e,
    0.3em 0.3em 1em rgba(34, 80, 108, 0.271);

  padding: 5px 10px;
  border-radius: 3em;
  button {
    border-radius: 50%;
    border: 0;
    font-weight: 600;
    cursor: pointer;
    font-size: $font-size-small;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-icon {
      padding: 5px 0px;
      margin: 0;
      display: flex;
    }
  }
  #btn-1 {
    background-color: $btn-card-color-bg-primary;
    color: $btn-card-color-primary;
  }
  #btn-2 {
    background-color: $btn-card-color-bg-secondary;
    color: $btn-card-color-secondary;
  }
  #btn-3 {
    background-color: $btn-card-color-bg-tertiary;
    color: $btn-card-color-tertiary;
  }
}

@media screen and (max-width: 1920px) {
  .btn-card__container {
    padding: 4px 7px ;
    border-radius: 2em ;
    gap: 8px ;
    button {
      font-size: $font-size-small * 0.75;
    }
  }
}
