.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #388ec3;
  justify-content: center;
  cursor: pointer;
  width: calc(100% - 24px);
  text-decoration: none;
  color: #fff;

  padding: 10px 10px;
  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    color: #fff;
    .login-icon {
      font-size: 1.7em;
    }
  }
  a:active {
    color: #fddc30;
  }
  .login-icon-deconnecte {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.7em;
    gap: 10px;
    .login-text-deconnecte {
      font-size: 0.6em;
    }
  }
  .login-icon-deconnecte:active {
    color: #fddc30;
  }
}
