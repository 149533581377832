.tabs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 121px;
  height: 55px;
  color: #fff;
  position: relative;
  cursor: pointer;

  .icon {
    font-size: 1.5em;
  }

  .name {
    font-size: 0.8em;
  }
}

.tabs-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../../../public/images/components/sidebar/tabs/yellow\ selected.svg");
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tabs-container.active .tabs-background {
  opacity: 1;
}

@media screen and (max-width: 1920px) {
  .tabs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 55px;
    color: #fff;
    position: relative;
    cursor: pointer;
  }

  .tabs-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    background-image: url("../../../../../public/images/components/sidebar/tabs/yellow\ selected.svg");
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

@media screen and (max-width: 750px) {
  .tabs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 55px;
    color: #fff;
    position: relative;
    cursor: pointer;

    .icon {
      font-size: 1.3em;
    }

    .name {
      font-size: 0.6em;
    }
  }

  .tabs-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    background-image: url("../../../../../public/images/components/sidebar/tabs/yellow\ selected.svg");
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}
