.card-content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;

  .top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    color: #ffffff;

    .card-content_top-text {
      font-size: 1.7em;
      animation: content-wrait 0.8s ease-in-out;

      h3 {
        font-weight: 400;
        position: relative;
        bottom: 10px;
      }
    }

    span {
      background-color: #ffffffc4;
      border-radius: 5px;
      padding: 5px 10px;
      color: black;
    }

    .card-content_image {
      margin-top: 30px;
      animation: content-wrait 1s ease-in-out;
      background-color: #0a171f;
      padding: 27px 30px 20px 30px;
      border-radius: 10px;

      img {
        object-fit: contain;
        max-height: 400px;
        border-radius: 10px;
      }
    }
  }

  .card-content_code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    height: 100%;
    width: 100%;

    .card-content_code-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 50px;
      border-radius: 5px;

      .techno {
        color: #fff;
        background-color: #0000002f;
        border-top: 2px solid #fddb3018;
        border-left: 2px solid #fddb3018;
        border-right: 2px solid #fddb3018;
        border-radius: 15px 15px 0 0;
        padding: 10px 20px;
        margin: 0;
      }

      .content-card {
        margin: 0;
        padding: 20px;
        background-color: #0a171f;
        border-radius: 5px;
        border: 2px solid #fddb3018;
        animation: content-wrait 0.7s linear;
        max-height: 600px;
        overflow-y: scroll;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;

        .normal-text {
          font-size: 0.9em;
          width: 100vh;
        }

        code {
          width: max-content;
        }

        // Styles pour la scrollbar
        &::-webkit-scrollbar {
          width: 10px; /* Largeur de la scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: #c1c1c1; /* Couleur de la piste */
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: #fddc30; /* Couleur de la barre */
          border-radius: 10px;

          &:hover {
            background: rgba(
              34,
              80,
              108,
              0.504
            ); /* Couleur de la barre au survol */
          }
        }
      }
    }
  }
}

// Animation d'apparition
@keyframes content-wrait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
