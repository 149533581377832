.container-card-content {
 height: 100%;
 width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #0b1722;
  animation: contain-wrait 0.3s ease-out;



  .btn-back {
    font-size: 2em;
    margin: 15px 0 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff00;
    border: none;
    color: yellow;
    cursor: pointer;
  }

  // Styles pour la scrollbar
  &::-webkit-scrollbar {
    width: 10px; // Largeur de la scrollbar
  }

  &::-webkit-scrollbar-track {
    background: #c1c1c1; // Couleur de la piste
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #fddc30; // Couleur de la barre
    border-radius: 10px;

    &:hover {
      background: rgba(34, 80, 108, 0.504); // Couleur de la barre au survol
    }
  }
}

// Animation pour le changement de fond
@keyframes contain-wrait {
  0% {
    background: #fff;
  }
  100% {
    background: #292f33;
  }
}

@media screen and (max-width: 1920px) {
  .container-card-content {
    height: 100%;
    width: 100%;
     overflow-y: auto;
     overflow-x: hidden;
     background: #0b1722;
     animation: contain-wrait 0.3s ease-out;
   
  }
}
