/* Container de chargement qui occupe toute la page */
.loader-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-modal {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader-modal:before,
.loader-modal:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader-modal:before {
  animation: ball1 1s infinite;
  background-color: #fddc30;
  box-shadow: 30px 0 0 #22506c;
  margin-bottom: 10px;
}
.loader-modal:after {
  animation: ball2 1s infinite;
  background-color: #22506c;
  box-shadow: 30px 0 0 #fddc30;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #22506c;
  }
  50% {
    box-shadow: 0 0 0 #22506c;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #22506c;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #fddc30;
  }
  50% {
    box-shadow: 0 0 0 #fddc30;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #fddc30;
    margin-top: 0;
  }
}
