.help-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  .help-title-section {
    align-items: left;
    padding-left: 50px;
    padding-bottom: 20px;
    color: #222222;

    h1 {
      font-size: 3em;
    }

    p {
      text-align: left;
      padding-right: 390px;
      line-height: 2em;
      position: relative;
      bottom: 20px;
    }
  }

  .help-collapses {
    margin-bottom: 50px;
  }

  .collapse-text {
    font-size: 1rem;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__intro {
      font-weight: bold;
      margin-bottom: 1rem;
    }

    &__list {
      margin-left: 1.5rem;
      list-style-type: disc;
    }

    &__list-item {
      margin-bottom: 1rem;
    }

    &__list-title {
      font-weight: bold;
      color: #333;
    }

    &__demo {
      margin-top: 2rem;
      padding: 1rem;
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      border-radius: 8px;
    }

    &__demo-title {
      font-weight: bold;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    &__demo-description {
      margin-bottom: 1rem;
    }

    &__guide {
      display: flex;
      flex-direction: column;
    }

    &__guide-step {
      margin-bottom: 2rem;

      &-text {
        display: block;
        margin-bottom: 0.5rem;
        text-align: left;
      }

      &-image {
        max-width: 100%;
        height: auto;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      &--one,
      &--two,
      &--three,
      &--four,
      &--five,
      &--six,
      &--seven {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }

      &--one {
        img {
          height: 28vw;
        }
      }
      &--two {
        img {
          height: 28vw;
        }
      }
      &--six {
        img {
          height: 35vw;
        }
      }
    }
  }

  .feedback-section {
    text-align: center;
    margin-bottom: 50px;

    h2 {
      font-size: 2em;
      color: #007bff;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.2em;
      color: #555;
      margin-bottom: 20px;
    }

    .feedback-textarea {
      width: 100%;
      height: 100px;
      padding: 10px;
      font-size: 1em;
      border: 2px solid #ddd;
      border-radius: 5px;
      margin-bottom: 20px;
      resize: none;
    }

    .feedback-submit {
      padding: 10px 20px;
      font-size: 1em;
      color: #fff;
      background-color: #007bff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
