.notes-content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  .notes-top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .notes-content_top-text {
      display: flex;
      font-size: 1.7em;

      h3 {
        color: #d6d6d6;
        font-weight: 400;
      }
    }
  }

  .notes-content_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    height: 100%;
    width: 100%;

    .card-content_note-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 55px;
      height: 100%;
      width: 100%;
    }

    .subtitle {
      color: #d6d6d6;
      background-color: #3c4a5a;
      padding: 10px 20px;
      position: relative;
      top: 21px;
      border-radius: 5px 5px 0 0;
    }

    .content-card-note {
      margin: 0;
      padding: 20px;
      background-color: #3c4a5a;
      border-radius: 10px;
      animation: content-wrait 0.7s linear;
      max-height: 600px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;

      .normal-text {
        font-size: 0.9em;
        width: 100vh;
        color: #d6d6d6;
      }

      code {
        width: max-content;
      }

      // Styles pour la scrollbar
      &::-webkit-scrollbar {
        width: 10px; /* Largeur de la scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: #c1c1c1; /* Couleur de la piste */
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #fddc30; /* Couleur de la barre */
        border-radius: 10px;

        &:hover {
          background: rgba(
            34,
            80,
            108,
            0.504
          ); /* Couleur de la barre au survol */
        }
      }
    }
  }
}

// Animation d'apparition
@keyframes content-wrait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
