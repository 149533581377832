.filter-bar_container {
  height: 74px;
  width: 193px;
  border-radius: 36px;
  background: #fff;
  box-shadow: -7px 7px 18px #cfcfcf;
  border: 2px solid #cbcbcb;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  animation: expand-out 0.3s ease;
  transition: 0.3s;
  .icon {
    font-size: 2em;
    margin-top: 9px;
    color: #b8b8b8;
  }

  .text {
    font-size: 1.1em;
    color: #b8b8b8;
  }
  .number-filter {
    padding: 2px 8px;
    background-color: #22506c;
    color: white;
    border-radius: 50%;
  }
}

.filter-bar_container-expand {
  height: 74px;
  width: 593px;
  border-radius: 36px;
  background: #fff;
  box-shadow: -7px 7px 18px #cfcfcf;
  border: 2px solid #cbcbcb;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  animation: expand-in 0.3s ease;
  transition: 0.3s;
  .text {
    display: none;
  }
  .icon {
    transform: rotate(-180deg);
    position: relative;
    bottom: 8px;
    transition: 0.5s;
  }
  .filter-elements {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    .custom-filtered {
      display: flex;
      flex-direction: row;
      
     
    } .filter-date{
        display: flex;
        flex-direction: column;
        .filter-date-title{
          font-size: .77em;
        }
      }
  }
}

@keyframes expand-in {
  0% {
    width: 193px;
    overflow: hidden;
  }
  100% {
    width: 593px;
    overflow : visible;
  }
}

@keyframes expand-out {
  0% {
    width: 593px;
  }
  100% {
    width: 193px;
  }
}
