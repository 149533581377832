// Couleurs
$primary-color: #22506c;
$secondary-color: #fddc30;
$background-color: #f1f1f1;
$text-color: #222222;
$white: #ffffff;
$black: #000000;
$btn-card-color-primary: #af0808;
$btn-card-color-secondary: #ae9407;
$btn-card-color-tertiary: #00a324;
$btn-card-color-bg-primary: #ffaaaa;
$btn-card-color-bg-secondary: #fff3b2;
$btn-card-color-bg-tertiary: #c4ffd1;

// Tailles
$navbar-height: 94px;
$sidebar-width: 122px;

// Breakpoints
$breakpoint-mobile: 750px;
$breakpoint-tablet: 1024px;
$breakpoint-desktop: 1920px;

// Ombres
$box-shadow: 5px 5px 10px #b9b9b9, -5px -5px 10px #ffffff;

// Transitions
$transition-speed: 0.3s;

// Bordures
$border-radius: 5px;

// Fonts
$font-size-small: 0.8em;
$font-size-medium: 1em;
$font-size-large: 1.2em;