.custom-select-container {
  position: relative;
  width: 200px;
  margin: 0 20px;
  border-radius: 5px;
}

.custom-select-selected {
  color: white;
  background-color: #22506c;
  padding: 10px;
  cursor: pointer;
  font-size: 0.7em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 3em;
  .chevron-select {
    padding-top: 5px;
    display: inline-block;
    transition: transform 0.5s cubic-bezier(0.4, -0.55, 0.27, 1.55);
  }
}

.custom-select-options {
  position: absolute;
  left: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: baseline;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  font-size: 0.7em;

  border-radius: 0 0 15px 15px;
  width: 150px;
}

.custom-select-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.custom-select-option:hover {
  background-color: #22506c;
  color: #fddc30;
  .delete-option-button {
    color: #fddc30;
  }
}

.delete-option-button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 0.9rem;
}

.delete-option-button:hover {
  text-decoration: underline;
}

.custom-select-animated {
  .chevron-select {
    transform: rotate(180deg);
  }
}

.loader-options {
  border: 2px solid #fddc30;
  border-top: 2px solid gray;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
