.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .carousel-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;

    .carousel-slide-video {
      height: 0;
      width: 80vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      transition: height 0.5s ease-in-out, background-color 0.5s ease-in-out;
      background-color: transparent;
      position: relative;

      .close-video-button {
        position: absolute;
        top: 0;
        background-color: #fddb30;
        border: none;
        padding: 10px 20px;
        border-radius: 15px;
        opacity: 0.7;
        cursor: pointer;
        font-weight: 600;
      }

      .close-video-button:active {
        background-color: #fff;
      }

      .close-video-button:hover {
        opacity: 1;
      }
    }

    .carousel-slide-image {
      background-color: #0a171f;
      padding: 27px 30px 20px 30px;
      border-radius: 10px;
      opacity: 1;

      animation: image-content-wrait 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
      img {
        height: 20vw;
        object-fit: contain;
        border-radius: 10px;
      }
    }
  }

  .carousel-controls {
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    animation: image-content-wrait 0.3s ease-in-out;

    button {
      background-color: #fddb30;
      border: none;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    button:active {
      background-color: #fff;
      transform: scale(90%);
    }
  }

  .show-video-button {
    position: relative;
    margin-bottom: 30px;
    top: 50px;
    background-color: #fddb30;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 600;
  }

  .show-video-button:active {
    background-color: #fff;
  }

  &.hide-content {
    .carousel-slide-image,
    .carousel-controls {
      opacity: 0;
    }
  }
}

@keyframes image-content-wrait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
