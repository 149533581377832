//Style pour le composant Logo.jsx
.logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
  width: 237px;
  height: 55px;
  cursor: pointer;

  .logo {
    font-size: 3em;
    color: #388ec3;
    margin-top: 15px;
  }
  .name {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    text-wrap: nowrap;
  }
}

@media screen and (max-width: 750px) {
  .logo-container {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: max-content;
    margin-left: 14px;
    position: relative;
    bottom: 22px;
    .logo {
      font-size: 1.3em;
      color: #388ec3;
      margin-top: 15px;
    }
    .name {
      font-size: 0.7em;
      text-transform: uppercase;
      font-weight: 600;
      color: #fff;
      text-wrap: nowrap;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
